<template>
  <div>
    <b-card title="Açık Uçlu Anketler">
      <div style="min-height: 280px">
        <b-table
          responsive
          selectable
          select-mode="single"
          hover
          class="mt-2 odd-even-table"
          :items="topicList"
          :per-page="topicsPerPage"
          :current-page="topicsCurrentPage"
          :fields="topicListTableFields"
          @row-selected="showQuestionsOfTopic"
        >
          <template #cell(index)="data">
            <p
              style="margin-bottom: 0px"
              :class="
                data.item == selectedTopic
                  ? 'text-primary font-weight-bold'
                  : ''
              "
            >
              {{ (topicsCurrentPage - 1) * topicsPerPage + data.index + 1 }}
            </p>
          </template>
          <template #cell(question_topic)="data">
            <p
              style="margin-bottom: 0px"
              :class="
                data.item == selectedTopic
                  ? 'text-primary font-weight-bold'
                  : ''
              "
            >
              {{ data.item.question_topic }}
            </p>
          </template>
          <template #cell(question_text)="data">
            <p
              style="margin-bottom: 0px"
              :class="
                data.item == selectedTopic
                  ? 'text-primary font-weight-bold'
                  : ''
              "
            >
              {{ data.item.question_text }}
            </p>
          </template>
          <template #cell(date_time)="data">
            <p style="margin-bottom: 0px">
              {{ dateTimeToDayText(data.item.date_time) }}
            </p>
          </template>
          <template #cell(last_access_time)="data">
            <p style="margin-bottom: 0px">
              {{ dateTimeToDayText(data.item.last_access_time) }}
            </p>
          </template>
          <template #cell(answer_count)="data">
            <p style="margin-bottom: 0px">
              {{ data.item.answer_count }}
            </p>
          </template>
          <template #cell(importance)="data">
            <span class="text-primary font-weight-bolder"
              >Önemli: {{ data.item.important_count }}
            </span>
            -
            <span class="text-warning font-weight-bolder"
              >Çok Önemli: {{ data.item.very_important_count }}
            </span>
          </template>
        </b-table>

        <div style="display: flex; margin: 20px 10px 20px 10px">
          <div style="flex-grow: 1"></div>
          <b-pagination
            v-if="topicList.length > topicsPerPage"
            v-model="topicsCurrentPage"
            :total-rows="topicList.length"
            :per-page="topicsPerPage"
          />
        </div>
      </div>
    </b-card>

    <b-card v-if="selectedTopic" style="margin-bottom: 300px">
      <h4>
        {{ selectedTopic.question_topic }} /
        {{ questionsOfSelectedTopic.length }} Cevap
      </h4>
      <div style="min-height: 360px">
        <b-table
          responsive
          selectable
          select-mode="single"
          hover
          class="mt-2 odd-even-table"
          :items="questionsOfSelectedTopic"
          :per-page="questionsPerPage"
          :current-page="questionsCurrentPage"
          :fields="questionListTableFields"
        >
          <template #cell(index)="data">
            {{ (questionsCurrentPage - 1) * questionsPerPage + data.index + 1 }}
          </template>
          <template #cell(full_name)="data">
            <p style="margin-bottom: 0px; min-width: 200px">
              {{ toTitleCase(data.item.name) }}
              {{ toTitleCase(data.item.surname) }} ({{ data.item.user_id }})
            </p>
          </template>

          <template #cell(phone)="data">
            <div class="phone-cell" style="display: flex">
              <p
                v-if="data.item.phone_verified"
                style="margin: 5px 10px 0px 0px; min-width: 120px"
                class="text-primary font-weight-bold"
              >
                {{ data.item.phone }}
              </p>
              <p
                v-else
                style="margin: 5px 10px 0px 0px; min-width: 120px"
                class="text-secondary"
              >
                {{ data.item.phone }}
              </p>

              <b-button
                v-if="data.item.phone"
                variant="flat-primary"
                @click="copyWhatsAppLink(data.item.phone)"
              >
                <img
                  src="@/assets/images/whatsapp.svg"
                  width="22"
                  height="22"
                  style="margin: 2px"
                />
              </b-button>
            </div>
          </template>

          <template #cell(user_answer)="data">
            <p style="margin-bottom: 0px">
              {{ data.item.user_answer }}
            </p>
          </template>
          <template #cell(importance)="data">
            <div class="importance-cell">
              <b-button-group>
                <b-button
                  :variant="
                    data.item.importance == 1 ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="changeImportance(data.item, 1)"
                  style="min-width: 90px"
                >
                  Önemli
                </b-button>
                <b-button
                  :variant="
                    data.item.importance == 2 ? 'warning' : 'outline-warning'
                  "
                  size="sm"
                  @click="changeImportance(data.item, 2)"
                  style="min-width: 100px"
                >
                  Çok Önemli
                </b-button>
              </b-button-group>
            </div>
          </template>
          <template #cell(reviewed)="data">
            <b-button
              size="sm"
              :variant="data.item.reviewed ? 'flat-primary' : 'flat-secondary'"
              @click="changeSeen(data.item)"
            >
              <span
                :class="
                  data.item.reviewed
                    ? 'align-middle font-weight-bolder'
                    : 'align-middle'
                "
                >{{ data.item.reviewed ? "İncelendi" : "Bekliyor" }}</span
              >
            </b-button>
          </template>
          <template #cell(action)="data">
            <div style="display: flex">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="showQuestionDetails(data.item)"
              >
                <span class="align-middle font-weight-bold"> Detaylar </span>
              </b-button>

              <b-button
                size="sm"
                variant="outline-danger"
                @click="showDeleteQuestionModal(data.item)"
                style="margin-left: 20px"
              >
                <span class="align-middle font-weight-bold"> Sil </span>
              </b-button>
            </div>
          </template>
        </b-table>

        <div style="display: flex; margin: 20px 10px 0px 10px">
          <div style="flex-grow: 1">
            <p class="text-primary font-weight-bolder">
              {{ getReviewDetailsOfSelectedTopic() }}
            </p>
          </div>
          <b-pagination
            v-if="questionsOfSelectedTopic.length > questionsPerPage"
            v-model="questionsCurrentPage"
            :total-rows="questionsOfSelectedTopic.length"
            :per-page="questionsPerPage"
          />
        </div>
      </div>
    </b-card>

    <b-modal
      id="modal-delete-question"
      ref="modal-delete-question"
      hide-footer
      centered
      size="sm"
      title="Cevabı Sil"
    >
      <p>Kullanıcının cevabı silinecek. Bu işlem geri alınamaz!</p>

      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="$refs['modal-delete-question'].hide()"
        >
          Kapat
        </b-button>
        <b-button
          class="mr-1"
          style="float: right"
          variant="danger"
          @click="deleteSelectedQuestion()"
        >
          Cevabı Sil
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-show-answer-details"
      ref="modal-show-answer-details"
      hide-footer
      centered
      size="md"
      title="Cevap Detayları"
    >
      <p v-if="selectedQuestion" v-html="JsonToHtml(selectedQuestion)"></p>

      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="$refs['modal-show-answer-details'].hide()"
        >
          Kapat
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BTabs,
  BTab,
  BFormTextarea,
  BButtonGroup,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BFormTextarea,
    BButtonGroup,
  },
  data() {
    return {
      topicList: [],
      topicListTableFields: [
        { key: "index", label: "" },
        { key: "question_topic", label: "Konu" },
        { key: "question_text", label: "Soru Metni" },
        { key: "date_time", label: "Yayınlanma Tarihi" },
        { key: "last_access_time", label: "Bitiş Tarihi" },
        { key: "answer_count", label: "Cevap Sayısı" },
        { key: "importance", label: "Önemli Cevaplar" },
      ],
      topicsPerPage: 5,
      topicsCurrentPage: 1,

      selectedTopic: null,
      questionsOfSelectedTopic: [],
      questionListTableFields: [
        { key: "index", label: "" },
        { key: "full_name", label: "Kullanıcı" },
        { key: "phone", label: "Telefonu" },
        { key: "user_answer", label: "Cevabı" },
        { key: "importance", label: "Önem", sortable: true },
        { key: "reviewed", label: "Durum", sortable: true },
        { key: "action", label: "İşlemler" },
      ],
      questionsPerPage: 10,
      questionsCurrentPage: 1,

      selectedQuestion: null,
    };
  },
  watch: {},

  computed: {},

  methods: {
    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: message,
          variant: "danger",
        },
      });
    },

    showSuccess(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "İşlem Başarılı!",
          icon: "BellIcon",
          text: message,
          variant: "success",
        },
      });
    },

    getReviewDetailsOfSelectedTopic() {
      if (
        this.questionsOfSelectedTopic &&
        this.questionsOfSelectedTopic.length > 0
      ) {
        var reviewedCount = 0;
        this.questionsOfSelectedTopic.forEach((question) => {
          if (question.reviewed) {
            reviewedCount++;
          }
        });
        return (
          reviewedCount +
          " cevap incelendi, " +
          (this.questionsOfSelectedTopic.length - reviewedCount) +
          " cevap bekliyor."
        );
      }
      return "";
    },

    showDeleteQuestionModal(question) {
      if (question) {
        this.selectedQuestion = question;
        this.$refs["modal-delete-question"].show();
      }
    },

    async deleteSelectedQuestion() {
      this.$refs["modal-delete-question"].hide();
      var response = await this.$store.dispatch(
        "adminOpenEndedSurveys/deleteUserAnswer",
        this.selectedQuestion.nq_id
      );
      this.questionsOfSelectedTopic = await store.dispatch(
        "adminOpenEndedSurveys/getAnswersOfTopic",
        this.selectedTopic.question_topic
      );
      this.$forceUpdate();
    },

    async showQuestionsOfTopic(topics) {
      this.questionsOfSelectedTopic = [];
      this.selectedQuestion = null;
      if (topics && topics.length > 0) {
        this.selectedTopic = topics[0];
      }
      this.questionsOfSelectedTopic = await store.dispatch(
        "adminOpenEndedSurveys/getAnswersOfTopic",
        this.selectedTopic.question_topic
      );
    },

    showQuestionDetails(question) {
      this.selectedQuestion = question;
      this.$refs["modal-show-answer-details"].show();
    },

    async changeSeen(question) {
      var payload = {
        nq_id: question.nq_id,
        importance: question.importance,
        reviewed: question.reviewed ? 0 : 1,
      };
      var updated = await store.dispatch(
        "adminOpenEndedSurveys/updateUserAnswer",
        payload
      );
      if (updated) {
        question.reviewed = !question.reviewed;
        this.$forceUpdate();
      }
    },

    async changeImportance(question, importance) {
      importance = question.importance == importance ? 0 : importance;
      var payload = {
        nq_id: question.nq_id,
        importance: importance,
        reviewed: 1,
      };
      var updated = await store.dispatch(
        "adminOpenEndedSurveys/updateUserAnswer",
        payload
      );
      if (updated) {
        question.importance = importance;
        question.reviewed = 1;
        this.$forceUpdate();
      }
    },

    async copyWhatsAppLink(phone) {
      if (!phone) {
        this.showError("Bir hata oluştu");
        return;
      }
      phone = phone.replaceAll(" ", "");
      if (phone.length == 11) {
        phone = "9" + phone;
      }
      if (phone.length == 10) {
        phone = "90" + phone;
      }
      var copyText = "https://api.whatsapp.com/send/?phone=" + phone;
      await navigator.clipboard.writeText(copyText);
      this.showSuccess("WhatsApp bağlantısı kopyalandı.");
    },

    JsonToHtml(json) {
      var jsonString = JSON.stringify(json);
      jsonString = jsonString.split("{").join("");
      jsonString = jsonString.split("}").join("");
      jsonString = jsonString.split(":").join(": ");
      jsonString = jsonString.split(',"').join(',<br/><br/>"');
      jsonString = jsonString.split('":').join(" ->");
      jsonString = jsonString.split('"').join("");
      return jsonString;
    },

    dateTimeToDayText(dateTime) {
      if (dateTime && dateTime.trim().length == 19) {
        dateTime = dateTime.trim();
        var date = dateTime.split(" ")[0];
        var year = date.split("-")[0];
        var month = date.split("-")[1];
        var day = date.split("-")[2];
        if (dateTime.slice(0, 10) == new Date().toISOString().slice(0, 10)) {
          return "Bugün";
        }
        switch (month) {
          case "01":
            return day + " Ocak " + year;
          case "02":
            return day + " Şubat " + year;
          case "03":
            return day + " Mart " + year;
          case "04":
            return day + " Nisan " + year;
          case "05":
            return day + " Mayıs " + year;
          case "06":
            return day + " Haziran " + year;
          case "07":
            return day + " Temmuz " + year;
          case "08":
            return day + " Ağustos " + year;
          case "09":
            return day + " Eylül " + year;
          case "10":
            return day + " Ekim " + year;
          case "11":
            return day + " Kasım " + year;
          case "12":
            return day + " Aralık " + year;
        }
      }
      return "-";
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .replace("(", "( ")
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            )
            .replace("( ", "(");
    },

    async loadQuestionTopics() {
      this.topicList = await store.dispatch(
        "adminOpenEndedSurveys/getQuestionTopics"
      );
      if (!this.selectedTopic && this.topicList && this.topicList.length > 0) {
        this.showQuestionsOfTopic([this.topicList[0]]);
      }
    },
  },
  async created() {
    let moduleId = "open_ended_surveys";
    let moduleName = "Açık Uçlu Anketler"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 
    await this.loadQuestionTopics();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@media only screen and (min-width: 1080px) {
  .modal-lg {
    max-width: 1024px;
  }
}

.phone-cell .btn {
  padding: 0px !important;
}

.importance-cell .btn-sm {
  padding: 0.486rem 0.75rem;
}
</style>
